// this is the client for exerv2
import React from 'react';
import ReactDOM from "react-dom/client";
import './index.css';
import App from './App';
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";

const UI_DEBUG='yes-auth'
// 'no-auth' means skip the auth0 stuff
// any other value means proceed with auth0

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
const config = getConfig();

const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  onRedirectCallback,
  authorizationParams: {
    redirect_uri: window.location.origin,
    ...(config.audience ? { audience: config.audience } : null),
    scope: "read:data write:data",
  },
};

const root = ReactDOM.createRoot(document.getElementById('root'));
if(UI_DEBUG=='no-auth')
  root.render(
      <App UI_DEBUG={UI_DEBUG}/>
  );  
else
  root.render(
    <Auth0Provider
      {...providerConfig}
    >
      <App UI_DEBUG={UI_DEBUG}/>
    </Auth0Provider>,
  );





