import React from "react";
import "./App.css";
import Settings from './Views/Settings.js';
import Workout from './Views/Workout.js';
import Review from './Views/Review.js';
import LoginButton from './Components/LoginButton.js';
import { useAuth0 } from "@auth0/auth0-react";

import {
	BrowserRouter as Router,
	Link,
	Routes,
	Route,
	// Outlet
} from "react-router-dom";
import { useEffect, useState } from "react";
const url = process.env.REACT_APP_NODE_URL+'/exertrackerdb/query';

function App({UI_DEBUG}) {
	const [isLocalDataValid, setLocalDataValid] = useState(false);
    const [exerObj, setExerObj] = useState({100:{name:"walk",value:"1 mile",selected:false}});
	const [textvalue, setTextvalue] = useState('');
	const [writeToken, setWriteToken] = useState(null);
	const [readToken, setReadToken] = useState(null);
	var { isAuthenticated, getAccessTokenSilently } = useAuth0();
	if(UI_DEBUG=='no-auth') {
		console.log('no auth0');
		isAuthenticated = false;
		getAccessTokenSilently=null;
	}
const exerparm = {
        exerObj, setExerObj, 
        textvalue, setTextvalue,
        writestate, readstate,
    }

	async function writestate(myobj, mytextvalue) {
		if(!isLocalDataValid) return;
		let myToken;
		if(writeToken===null){
		  const accessToken = await getAccessTokenSilently({
			audience: 'https://sunfishapp.herokuapp.com/exertrackerdb',
			scope: "write:data",
		  });
		  console.log('Got write token')
		  setWriteToken(accessToken);
		  myToken = accessToken;
		}
		else{
		  myToken = writeToken;
		}

		const mypayload = {payload: JSON.stringify(
			{"types": myobj, "log": mytextvalue})};

		fetch(url, {
			method: 'PUT',
			headers: { 'Content-Type': 'application/json;charset=utf-8',
					  Authorization: `Bearer ${myToken}`},
			body: JSON.stringify(mypayload)
		});  
		console.log('Wrote to remote server')
	}

	async function readstate() {
		if (!isAuthenticated) return;
		let myToken;
		if(readToken===null){
		  const accessToken = await getAccessTokenSilently({
			audience: 'https://sunfishapp.herokuapp.com/exertrackerdb',
			scope: "read:data",
		  });
		  setReadToken(accessToken);
		  myToken = accessToken;
		}
		else{
		  myToken = readToken;
		}

        // retrieves from remote cloud DB, stores in local

        fetch(url, {headers: {Authorization: `Bearer ${myToken}`}})
        .then(res => res.json())
        .then(res=>{ 
			setLocalDataValid(true);  
            setExerObj(JSON.parse(res.body)["types"])
            setTextvalue(JSON.parse(res.body)["log"])
        })
        .catch(err=>alert("Error in readstate(): "+err));
    }
	
	useEffect(()=>{
		// put any intialization code here
		// note that this is called before authenticated,
		// so cannot yet access server data
	}, []);
	if(UI_DEBUG!='no-auth') {
		if (!isAuthenticated) {
			return (
			  <div style={{
				  display:'flex',
				  justifyContent:'center',
				  width: '200px',
						  }}>
				  <LoginButton />;
			  </div>
			)
		  }
	  
	}


	return (
		<>
			<Router>
				<div className='navtext'>
					<Link to="/workout" className='navtext'
						style={{textDecoration:'none'}}>
						<button className='navbtn'> Workout </button>
					</Link>
					<Link to="/review" className='navtext'
						style={{textDecoration:'none'}}>
						<button className='navbtn'> Review </button>
					</Link>
					<Link to="/settings" className='navtext'
						style={{textDecoration:'none'}}>
						<button className='navbtn'> Settings </button>
					</Link>
				</div>

				<Routes>
					<Route index element={<Workout {...exerparm}/>} />
					<Route path="/workout" 
						element={<Workout {...exerparm} />} />
					<Route path="/review" 
						element={<Review {...exerparm} />} />
					<Route path="/settings" 
						element={<Settings {...exerparm} />} />
				</Routes>
			</Router>
		</>
	);
}

export default App;


