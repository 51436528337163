import React from "react";
import "../Components/Button.css";
import "../App.css";
import PullCloud from '../Components/PullCloud/PullCloud.js';
import SubmitButton from '../Components/SubmitButton/SubmitButton.js';
import ToggleSelect from '../Components/ToggleSelect/ToggleSelect.js';
import StopWatch from '../Components/StopWatch/StopWatch.js';
import TextLog from '../Components/TextLog/TextLog.js';
import TextBottom from '../Components/TextBottom/TextBottom.js';
import LogoutButton from '../Components/LogoutButton/LogoutButton.js';
import ExerElement from '../Components/ExerElement/ExerElement.js';

export function copyExer(exer1) {
	let a = new Object();
	a.name=exer1.name;
	a.value=exer1.value;
	a.selected=exer1.selected;
	return a;
}

export default function Workout(
	{
		exerObj, setExerObj, 
        textvalue, setTextvalue,
        writestate, readstate,
	}
	) {
	const exerparm = {
		exerObj, setExerObj, 
		textvalue, setTextvalue,
		writestate, readstate,
	}
	
	function handleChangeForm(id, val){
		let newobj = JSON.parse(JSON.stringify(exerObj));
		newobj[id].value = val;
		setExerObj(newobj);
		writestate(newobj, textvalue);
	}
	function handleToggle(id){
		let newobj = JSON.parse(JSON.stringify(exerObj));
		newobj[id].selected = !(newobj[id].selected)
		setExerObj(newobj);
		writestate(newobj, textvalue);
	}
	function genexer(){
	  let ret=[];
	  let exerlist = Object.entries(exerObj);
	  exerlist.sort((a, b) => a[0]-b[0]);
	  for(let entry of exerlist){
		ret.push(<ExerElement exername={entry[1].name}
		  id={entry[0]} value={entry[1].value} 
		  selected={entry[1].selected}
		  onChangeForm= {(id,value)=>handleChangeForm(id,value)}
		  onToggle = {(id)=>handleToggle(id)} />); 
	  }
	  return(ret);
	}
  
	return (
		<div>
			<br /> <br />
			<div className='firstgroup'>
				<PullCloud {...exerparm}/>
				<ToggleSelect {...exerparm} />
			</div> <br />
			<StopWatch />
			<br /> <br /> <br /> 
			<div style={{display:'flex', justifyContent:'center'}}>
				<SubmitButton {...exerparm}/>
			</div>
			<br />
			{genexer()}
			<br />
			<div style={{display:'flex', justifyContent:'center'}}>
				<SubmitButton {...exerparm}/>
			</div>
			<br />
			<TextLog {...exerparm} />
			<br />
			<div className='firstgroup'>
				<TextBottom />
				<LogoutButton />
			</div>
			<br /> <br /> <br /> <br /> <br /> <br />
		</div>
	);
  };
