import React from "react";
import "./Timer.css";

export default function Timer({time,isRunning}) {
    return (
        <div className="timer" >
        <span className="digits"
            style={{'color':isRunning?'aqua':'#7fffd4'}}
        >
            {("0" + Math.floor((time / 60000) % 60)).slice(-2)}:
        </span>
        <span className="digits"
            style={{'color':isRunning?'aqua':'#7fffd4'}}
        >
            {("0" + Math.floor((time / 1000) % 60)).slice(-2)}
        </span>
        </div>
    );
}
