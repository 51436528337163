import React, {useRef, useState, useEffect} from 'react';
import "../../App.css";
 
export default function TextBox(props) {
    const textareaRef = useRef(); 
	const [val, setVal] = useState(props.value);
	const [cursorPos, setCursorPos] = useState(0);

	const handleChange = (e) => {
		props.onChange(e.target.value);
		setVal(e.target.value);
	}

	const handleCursor = () => {setCursorPos(textareaRef.current.selectionStart)}
		
	const textStyle = {
		fontFamily: 'Courier New, monospace', // Set the font-family to a monospaced font
		'fontSize': '20px',
		width: `${val.length}ch`,
		minWidth: '100%'
	};
	const barStyle = {
		fontFamily: 'Courier New, monospace', // Set the font-family to a monospaced font
		'font-size': '20px',
		height: '1px',
		outline:'none',
		backgroundColor: 'aqua', 
		width: `${Math.max(0,val.substring(0,cursorPos).length-1)}ch`, // Set the width based on the number of characters
	};
	
	useEffect(() => {
		console.log('useffect: cursor='+cursorPos+
			' text='+val.substring(0,cursorPos));
	}, [cursorPos,val]);

	return (
		<div style={{
			display:'flex',
			flexDirection:'column',
			width:'55%',
		}}>
			<input
				style={textStyle}
				value={props.value} 
				ref={textareaRef}
				onChange={(e)=>handleChange(e)} 
				onBlur={handleCursor}
			/>
			<input style={barStyle}/>
		</div>

	);
	
}




